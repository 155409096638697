import cn from 'classnames'
import { useScrollInterpolate } from 'common/hooks'
import { add, dot, mul, norm, Vector } from 'common/vec'
import { HTMLProps, useEffect, useRef } from 'react'
import ClientSide from './ClientSide'

interface LineParticleProps {
    num: number
}

function timeInterp(t: number) {
    return Math.max(0, (2 / (1 + Math.pow(t * 1 - 1, 2))) - 1)
}

const rows = 10

function distInterp(row: number, center: number = 0.5, speed: number = 2) {
    const x = row / rows

    return Math.min(1, Math.max(0, 2 / (1 + Math.pow(speed * (x - center), 2)) - 1))
}

function genRandomPos(): [Vector, number] {
    const row = Math.floor(Math.random() * rows * 1.5)
    const n = 1000 / rows / 1
    const origin: Vector = [0, row * n]
    const dx = dot([1, -0.5], [2 * (Math.random() - 0.5) * 1000, 0])
    return [add(origin, mul(norm([1, -0.5]), dx)), row]
}


class LineUpdater {
    offset!: number
    time!: number
    length!: number
    speed!: number
    maxLength!: number
    row!: number
    pos!: Vector

    constructor() {
        this.reset()
    }

    update(dt: number) {
        this.time += dt
        if (this.time > 2000) {
            this.reset()
            return
        }

        this.length = timeInterp(this.time / 1000) * this.maxLength
        const s = this.length / this.maxLength * this.speed + 2
        // const s = Math.max(0.2, this.length / this.maxLength) * this.speed
        this.pos = add(this.pos, mul(norm([1, -0.5]), s))

    }

    reset() {
        this.offset = Math.random() * 2000
        this.time = -this.offset;
        [this.pos, this.row] = genRandomPos()
        // this.speed = Math.random() * 7 + 2
        this.length = 0
        this.maxLength = Math.random() * 100
        this.speed = this.maxLength / 100 * 10 + 3
    }
}

function LineParticle({ num }: LineParticleProps) {
    const ref = useRef<SVGGElement | null>(null)

    useEffect(() => {
        if (typeof window === 'undefined') return

        const updaters = new Array(num).fill(0).map(() => new LineUpdater())
        let start = Date.now()
        function update() {
            const now = Date.now()
            if (ref.current === null) {
                start = now
                raf = requestAnimationFrame(update)
                return
            }

            const dt = now - start
            start = now
            // const dt = 1000 / 60.0

            for (let i = 0; i < num; i++) {
                const node = ref.current.childNodes[i] as SVGLineElement
                const updater = updaters[i]
                updater.update(dt)

                const s = add(updater.pos, mul(norm([1, -0.5]), -updater.length / 2))
                const e = add(updater.pos, mul(norm([1, -0.5]), updater.length / 2))

                node.setAttribute('x1', `${s[0]}`)
                node.setAttribute('y1', `${s[1]}`)
                node.setAttribute('x2', `${e[0]}`)
                node.setAttribute('y2', `${e[1]}`)
                node.setAttribute('stroke', `hsla(0, 0%, 100%, ${Math.max(0, Math.min(100, Math.pow(updater.row / rows, 2) * 2 * 100)).toFixed(0)}%)`)
                // node.setAttribute('stroke', stroke)
            }

            raf = requestAnimationFrame(update)
        }

        let raf = requestAnimationFrame(update)
        return () => {
            cancelAnimationFrame(raf)
        }
    }, [num])

    return (
        <g ref={ref}>
            {new Array(num).fill(0).map((_, i) => (
                <line key={i} strokeWidth={1} />
            ))}
        </g>
    )
}

interface LineAxisProps {
    row: number
    isColored?: boolean
}

function LineAxis({ row, isColored }: LineAxisProps) {
    const n = 2000 / rows / 2
    const start: Vector = [0, row * n]
    const dx = dot([1, -0.5], [2000, 0])
    const end = add(start, mul(norm([1, -0.5]), dx))

    return <line
        x1={start[0]}
        y1={start[1]}
        x2={end[0]}
        y2={end[1]}
        strokeWidth={1}
        stroke={`hsla(353deg, ${distInterp(row, 1, 3) * 67}%, 60%, ${(0.01 +  0.1 * distInterp(row, 0.75, 3) + distInterp(row, 1, 7) * 0.5) * 100}%)`}
    />
}

interface Props {
    maxScroll: number
}

function LinesAnimationClient({
    maxScroll
}: Props) {
    const ref = useRef<SVGSVGElement | null>(null)
    useScrollInterpolate(0, maxScroll, f => {
        if (ref.current == null) return
        ref.current.style.transform = `translate3d(0, ${-474 * f}px, 0)`
    }, ref)

    return (
        <svg ref={ref} viewBox='0 0 1000 1000' preserveAspectRatio="xMidYMid slice" style={{ height: 'calc(100vh + 474px + 200px)', width: '100vw', bottom: -474, top: -74 }} className='fixed overflow-hidden inset-0 -z-10' xmlns="http://www.w3.org/2000/svg">
            {new Array(rows * 4).fill(0).map((_, i) => (
                <LineAxis row={i} key={`axis${i}`} />
            ))}
            <LineParticle num={Math.floor(rows * 1.5)} />
        </svg>
    )
}

export default function LinesAnimation(props: Props) {
    return (
        <ClientSide>
            <LinesAnimationClient {...props} />
        </ClientSide>
    )
}