import cn from 'classnames'
import { useIsDarkMode } from '../common/hooks'
import PaymentFormSetup from '../payment-form-setup/Form'
import Layout from '../components/Layout'
import { ReactNode } from 'react'
import AppProviders from 'components/AppProviders'
import LinesAnimation from 'ui/LinesAnimation'
import Head from 'next/head'
import { useIntl } from 'react-intl'

export default function Landing() {
    const intl = useIntl()
    const isDarkMode = useIsDarkMode()

    return (
        <>
            <Head>
                <title>
                    {intl.formatMessage({
                        defaultMessage: 'Start building your payment flow | repaya.io', id: 'UQpkhe'
                    })}
                </title>
            </Head>
            <LinesAnimation maxScroll={1} />
            <div className="flex flex-col justify-start items-center w-full relative z-10">
                <div style={{ height: '14vh' }}></div>
                <div className="flex flex-row items-center justify-center w-full">
                    <PaymentFormSetup formKey='' />
                </div>
                <div className='block sm:hidden' style={{ height: '40vh' }}></div>
            </div>
        </>
    )
}

Landing.getLayout = function ({ children }: { children?: ReactNode }) {
    return (
        <AppProviders>
            <Layout>
                {children}
            </Layout>
        </AppProviders>
    )
}