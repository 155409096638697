export type Vector = [number, number]

export function add(a: Vector, b: Vector): Vector {
    return [a[0] + b[0], a[1] + b[1]]
}

export function neg(a: Vector): Vector {
    return [-a[0], -a[1]]
}

export function sub(a: Vector, b: Vector): Vector {
    return add(a, neg(b))
}

export function len(a: Vector): number {
    return Math.pow(a[0] * a[0] + a[1] * a[1], 0.5)
}

export function dist(a: Vector, b: Vector): number {
    return len(sub(a, b))
}

export function mul(a: Vector, f: number): Vector {
    return [a[0] * f, a[1] * f]
}

export function norm(a: Vector): Vector {
    const l = len(a)
    return mul(a, 1.0 / l)
}

export function dot(a: Vector, b: Vector): number {
    return a[0] * b[0] + a[1] * b[1]
}